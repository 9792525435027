import React, {Component} from 'react';
import {CardElement, injectStripe} from 'react-stripe-elements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state={
      message:'If you had fun consider throwing a few beans this way!',
      formStyling:{color:'black'}
    }
    this.updateMessage = this.updateMessage.bind(this)
    this.hideForm = this.hideForm.bind(this)
  }

  updateMessage(message){
    this.setState({
      message:message
    })
  }

  hideForm(){
    this.setState({
      formStyling:{display:'none'}
    })
  }

  showStripeButton(){
    return (
      <div>
        <p>{this.state.message}</p>
        <CardElement />
        <button onClick={this.submit}>Send</button>
      </div>
    )
  }

  async submit(ev) {
    let {token} = await this.props.stripe.createToken({name: "Bobo",amount:75});
    let response = await fetch("/charge", {
      method: "POST",
      headers: {"Content-Type": "text/plain"},
      body: token.id
    });
    this.updateMessage(<FontAwesomeIcon icon={faSpinner} />);

    if (response.ok){
        this.hideForm()
        this.updateMessage('Donation Received. Thank you for your support!')
    } else {
        this.updateMessage('something went wrong')
    }
  }

  render() {

    return (
      <div className="checkout">
        <p>{this.state.message}</p>
        <div id="stripeBlock" style={this.state.formStyling}>
          <CardElement />
          <button onClick={this.submit}>Send</button>
        </div>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);
